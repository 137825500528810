import React from "react";
import useCurrency from "../../../hooks/useCurrency.js";
import Button from "../../../shared/components/button";
import styles from "./styles.module.css";

const Card = ({ title, thumbnail, price, level, category }) => {
  const { currency } = useCurrency();
  return (
    <div>
      <div className={styles.card}>
        <div className={styles.imgContainer}>
          <img
            className={styles.img}
            src={
              thumbnail?.filePath
                ? `${process.env.REACT_APP_API}${thumbnail?.filePath}`
                : thumbnail
            }
            alt="cart item"
          />
        </div>

        <div className={styles.info}>
          <div className={styles.group}>
            <p className={styles.title}>{category}</p>
            <div className={styles.price}>
              {price || 0}
              {currency?.code}
            </div>
          </div>
          <div className={styles.textContainer}>
            <p>{title}</p>
            <p>{level}</p>
          </div>

          <div className={styles.box}>
            <p>⭐4.7</p>
            <div className={styles.buttons}>
              <Button varaint="outline" text="Neveau" className={styles.btn} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
