import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useRef, useState } from "react";
import { useQuiz } from "../../../../hooks/QuizContext";
import styles from "./styles.module.css";

const QuestionRow = ({ question, setOpenModal, index }) => {
  const { setCurrentQuestion, quiz } = useQuiz();
  const [isOpen, setIsOpen] = useState(false);
  const answerRef = useRef();

  const handleSetQuestion = () => {
    const indexOfQuestion = quiz.questions.findIndex(
      (q) => q.question === question.question
    );

    setCurrentQuestion({
      id: quiz.questions[indexOfQuestion].question,
      number: indexOfQuestion
    });
    setOpenModal(false);
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.faqItem}>
          <button className={styles.question}>
            <div onClick={() => setIsOpen(!isOpen)} className={styles.box}>
              <BookmarkBorderOutlinedIcon
                style={{
                  color: question.bookmarked
                    ? "var(--primary-color)"
                    : "#A6A6A6"
                }}
              />
              <span className={isOpen ? styles.active : ""}>
                <ExpandMoreIcon style={{ color: "#A6A6A6" }} />
              </span>
              <span>Question {index + 1}</span>
              <span
                className={`${styles.answered} ${
                  question.answers && styles.active
                }`}
              >
                {question.answers ? "Answered" : "Unanswered"}
              </span>
            </div>
            <span onClick={handleSetQuestion} className={styles.arrow}>
              <KeyboardArrowDownIcon style={{ transform: "rotate(-90deg)" }} />
            </span>
          </button>
          <div
            ref={answerRef}
            style={{
              maxHeight: isOpen ? `${answerRef.current.scrollHeight}px` : "0"
            }}
            className={`${styles.answer} ${isOpen ? styles.open : ""}`}
          >
            <p>{question?.questionText}?</p>
            <p>
              (Choose The
              {question.answersCount === 1
                ? " Best Answer "
                : " " + question.answersCount + " Best Answers "}
              ){" "}
            </p>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
};

export default QuestionRow;
