import React from 'react';
import { usePersonalize } from './usePersonalize';
import { IoMdArrowDropright } from "react-icons/io";
import ProgressBar from './ProgressBar';
import Profile from './Profile';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import styles from "./styles.module.css";
import Footer from "../Home/Footer";
const StepperForm = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { currentStep, formData, setFormData, profileCompleted, handleNext, handleBack, goToHome } = usePersonalize(user._id);

  const handleInputChange = (stepData) => setFormData((prev) => ({ ...prev, ...stepData }));


  return (
    <>
      <div className={styles.backImage}>
        <div className={styles.padding}>
          <div className={styles.logo}>
            <img
              src="./images/home/logoblanc.png"
              alt=""
              className={styles.logoimag}
            />
          </div>

          <Profile profileCompleted={profileCompleted} user={user} />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.containerHeader}>
          <span>Personalize your journey
            <p className={styles.underline}></p>
          </span>
          <button className={styles.close} onClick={goToHome}>
            Home
            <IoMdArrowDropright size={30} />
          </button>
        </div>
        <ProgressBar profileCompleted={profileCompleted} />

        {currentStep === 0 && <Step1 data={formData.stepPersonalize_1} onInputChange={handleInputChange} />}
        {currentStep === 1 && <Step2 data={formData.stepPersonalize_2} onInputChange={handleInputChange} />}
        {currentStep === 2 && <Step3 data={formData.stepPersonalize_3} onInputChange={handleInputChange} />}
        {currentStep === 3 && <Step4 data={formData.stepPersonalize_4} onInputChange={handleInputChange} />}

        <div className={styles.formnavigation}>
          {currentStep < 3 ? (
            <button onClick={handleNext} className={styles.nextbutton}>Next &gt;</button>
          ) : (
            <button onClick={handleNext} className={styles.nextbutton}>Finish</button>
          )}
          {currentStep > 0 && <button onClick={handleBack} className={styles.backbutton}> &lt; Back</button>}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default StepperForm;
