import styles from './styles.module.css';
import Avatar from "@mui/material/Avatar";
import img from "../assets/profileImgNoUp.svg";

const Profile = ({ profileCompleted, user }) => {

  const progressGradient = profileCompleted <= 20 ? '#E74C3C' : profileCompleted <= 80 ? '#F39D6E' : '#49C382';
  const mainColorRgb = profileCompleted <= 20 ? '255, 152, 0' : profileCompleted <= 80 ? '76, 175, 80' : '255, 152, 0';
  const completedPercentage = `${profileCompleted}%`
  return (
    <div>
      <a type="button" className={styles.nav_btn_profile}>
        <div className={styles.progressCircle}
          style={{
            '--completed-percentage': completedPercentage,
            '--progress-gradient': progressGradient,
            '--main-color-rgb': mainColorRgb
          }}
        >
          <div className={styles.progressInnerGap}>
            <div className={styles.progressInner}>
              {user.image ? (
                <Avatar
                  alt="icon"
                  src={`${process.env.REACT_APP_API}${user.image.filePath}`}
                  sx={{ width: 150, height: 150 }}
                />
              ) : (
                <Avatar
                  alt="icon"
                  src={img}
                  sx={{ width: 150, height: 150 }}
                />
              )}
            </div>
          </div>
        </div>
        <span>{user.name}
          <p className={styles.underline}></p>
          <p className={styles.profileType}>Candidat</p>
        </span>

      </a>
    </div>
  );
};

export default Profile;
