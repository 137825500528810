import { useNavigate, useParams } from "react-router-dom";

const useNavigateStep = () => {
  const { step, quizId } = useParams();
  const navigate = useNavigate();
  const stepNumber = parseInt(step);

  const handleNavigateNext = () => {
    if (stepNumber === 3) return;
    navigate(`/quiz/details/${quizId}/${stepNumber + 1}`);
  };

  const handleNavigatePrevious = () => {
    if (stepNumber === 1) return;
    navigate(`/quiz/details/${quizId}/${stepNumber - 1}`);
  };

  return {
    stepNumber,
    handleNavigateNext,
    handleNavigatePrevious
  };
};

export default useNavigateStep;
