import React from 'react';
import styles from "./styles.module.css";
const Step3 = ({ data, onInputChange }) => {
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const availability = checked
      ? [...data.availability, value]
      : data.availability.filter((avail) => avail !== value);

    onInputChange({ stepPersonalize_3: { ...data, availability } });
  };

  const handleRadioChange = (name) => (e) => onInputChange({ stepPersonalize_3: { ...data, [name]: e.target.value } });

  return (
    <div  className={styles.formcontent}>
      <p>Availability and Commitment</p>
   
<div className={styles.personlizestep}>
  <div className={styles.personlizesquestion}>
    <label>What is your preferred learning style? (Select all that apply)</label>
    <div className={styles.checkboxGroup}>
      <input type="checkbox" id="videos" name="availability" className={styles.hiddenCheckbox} value='Interactive videos' checked={data.availability.includes("Interactive videos")} onChange={handleCheckboxChange} />
      <label htmlFor="videos" className={styles.customLabel}>Interactive videos</label>
    </div>
    <div className={styles.checkboxGroup}>
      <input type="checkbox" id="liveClasses" name="availability" className={styles.hiddenCheckbox} value='Live Classes with an Instructor' checked={data.availability.includes("Live Classes with an Instructor")} onChange={handleCheckboxChange} />
      <label htmlFor="liveClasses" className={styles.customLabel}>Live Classes with an Instructor</label>
    </div>
    <div className={styles.checkboxGroup}>
      <input type="checkbox" id="readingMaterials" name="availability" className={styles.hiddenCheckbox} value='Reading Materials & Texts' checked={data.availability.includes("Reading Materials & Texts")} onChange={handleCheckboxChange} />
      <label htmlFor="readingMaterials" className={styles.customLabel}>Reading Materials & Texts</label>
    </div>
    <div className={styles.checkboxGroup}>
      <input type="checkbox" id="exercises" name="availability" className={styles.hiddenCheckbox} value='Practical Exercises & Quizzes' checked={data.availability.includes("Practical Exercises & Quizzes")} onChange={handleCheckboxChange} />
      <label htmlFor="exercises" className={styles.customLabel}>Practical Exercises & Quizzes</label>
    </div>
    <div className={styles.checkboxGroup}>
      <input type="checkbox" id="mixedFormats" name="availability" className={styles.hiddenCheckbox} value='Mixed Formats' checked={data.availability.includes("Mixed Formats")} onChange={handleCheckboxChange} />
      <label htmlFor="mixedFormats" className={styles.customLabel}>Mixed Formats</label>
    </div>
  </div>
    <div >
    <div className={styles.personlizesquestion}>
      <label>How much time can you dedicate to learning each week ?
      </label>
      <div className={styles.checkboxGroup}>
        <input
          type="radio"
          id="hours"
          name="hoursperweek"
          className={styles.hiddenCheckbox}
          value="Less than 5 hours"
          checked={data.hoursperweek === "Less than 5 hours"} onChange={handleRadioChange("hoursperweek")}
        />
        <label htmlFor="hours" className={styles.customLabel}>
          Less than 5 hours
        </label>
      </div>

      <div className={styles.checkboxGroup}>
        <input
          type="radio"
          id="morehours"
          name="hoursperweek"
          className={styles.hiddenCheckbox}
          value="5 to 10 hours"
          checked={data.hoursperweek === "5 to 10 hours"} onChange={handleRadioChange("hoursperweek")}
        />
        <label htmlFor="morehours" className={styles.customLabel}>
          5 to 10 hours
        </label>
      </div>

      <div className={styles.checkboxGroup}>
        <input
          type="radio"
          id="more"
          name="hoursperweek"
          className={styles.hiddenCheckbox}
          value="More than 10 hours"
          checked={data.hoursperweek === "More than 10 hours"} onChange={handleRadioChange("hoursperweek")}
        />
        <label htmlFor="more" className={styles.customLabel}>
          More than 10 hours
        </label>
      </div>

    </div>
    <div className={styles.personlizesquestion}>
      <label >Are you willing to participate in collaborative activities with other learners?</label>
    <div className={styles.personlizeQuestionMini}>
        <div className={styles.checkboxGroup}>
          <input type="radio" id="yes" name="learningother"
            className={styles.hiddenCheckbox} value='yes'  checked={data.learningother === "yes"} onChange={handleRadioChange("learningother")} />
          <label htmlFor="yes" className={styles.customLabelMini}> Yes</label>
    
        </div>
    
        <div className={styles.checkboxGroup}>
          <input type="radio" id="no" name="learningother"
            className={styles.hiddenCheckbox} value='no'  checked={data.learningother === "no"} onChange={handleRadioChange("learningother")} />
          <label htmlFor="no" className={styles.customLabelMini}> No</label>
          </div>
      </div>
    </div>
    </div>
</div>
</div>
  );
};

export default Step3;
