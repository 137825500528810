import React from 'react';
import styles from './styles.module.css';

const ProgressBar = ({ profileCompleted }) => {
  const progress = `${profileCompleted}%`;
  const progressGradient = profileCompleted <= 20 ? '#E74C3C' : profileCompleted <= 80 ? '#F39D6E' : '#49C382';

  return (
    <div className={styles.progressContainer}>
      <div
        className={styles.progressbar}
        style={{ width: progress, backgroundColor: progressGradient }}
      />
    </div>
  );
};

export default ProgressBar;
