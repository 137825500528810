import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaCheckCircle } from "react-icons/fa";
import styles from "./styles.module.css";

const VerifyEmail = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyEmail = async () => {
      const params = new URLSearchParams(search);
      const token = params.get("token");

      console.log("myy tokennnnnnnn", token);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}api/candidat/verify-email?token=${token}`
        );
        console.log("response is ", response);

        setTimeout(() => {
          navigate("/login");
          setIsLoading(false);
        }, 3000);
      } catch (error) {
        console.error("Error verifying email:", error);
        setIsLoading(false);
      }
    };

    verifyEmail();
  }, [search]);

  return (
    <div>
      <>
        {" "}
        <div className={styles.verificationMessage}>
          <FaCheckCircle className={styles.verificationIcon} />
          <span>Your Email is Verified</span>
        </div>
        <div className={styles.spinner}></div>
        <div className={styles.verificationMessage}>
        <span>Redirecting to login</span>
        </div>
        
      </>
    </div>
  );
};

export default VerifyEmail;
