import React from 'react';
import styles from "./styles.module.css";
const Step2 = ({ data, onInputChange }) => {
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const goals = checked
      ? [...data.goals, value]
      : data.goals.filter((goal) => goal !== value);

    onInputChange({ stepPersonalize_2: { ...data, goals } });
  };

  const handleRadioChange = (e) => onInputChange({ stepPersonalize_2: { ...data, timeline: e.target.value } });

  return (
    <div className={styles.formcontent}>
      <p>Goals and Aspirations</p>
      <div className={styles.personlizestep}>
        <div className={styles.personlizesquestion}>
          <label>What are your main objectives for using this platform? (Select all that apply)</label>
          <div className={styles.checkboxGroup}>
            <input type="checkbox" value="Skill Development for Work" id="skill" name="goals" className={styles.hiddenCheckbox} checked={data.goals.includes("Skill Development for Work")} onChange={handleCheckboxChange} />
            <label htmlFor="skill" className={styles.customLabel}>
              Skill Development for Work
            </label>
          </div>

          <div className={styles.checkboxGroup}>
            <input type="checkbox" value="Exploring New Interests" id="newinterest" name="goals" className={styles.hiddenCheckbox} checked={data.goals.includes("Exploring New Interests")} onChange={handleCheckboxChange} />
            <label htmlFor="newinterest" className={styles.customLabel}>Exploring New Interests</label>
          </div>
          <div className={styles.checkboxGroup}>
            <input type="checkbox" id="specificproject" name="goals" value='Preparing for a Specific Project ' className={styles.hiddenCheckbox} checked={data.goals.includes('Preparing for a Specific Project ')} onChange={handleCheckboxChange} />
            <label htmlFor="specificproject" className={styles.customLabel}>Preparing for a Specific Project </label>
          </div>
          <div className={styles.checkboxGroup}>
            <input type="checkbox" id="other" name="goals" value='Other (Specify)' className={styles.hiddenCheckbox} checked={data.goals.includes('Other (Specify)')} onChange={handleCheckboxChange} />
            <label htmlFor="other" className={styles.customLabel}>Other (Specify)</label>

          </div>
        </div>
        <div className={styles.personlizesquestion}>
          <label>What is your expected timeline to achieve these goals?</label>
          <div className={styles.checkboxGroup}>
            <input type="radio" id="month" value="month" name="timeline" className={styles.hiddenCheckbox} checked={data.timeline === "month"} onChange={handleRadioChange} />
            <label htmlFor="month" className={styles.customLabel} > month </label>
          </div>
          <div className={styles.checkboxGroup}>
            <input type="radio" id="1month" value="1-3 months" name="timeline" className={styles.hiddenCheckbox} checked={data.timeline === "1-3 months"} onChange={handleRadioChange} />
            <label htmlFor="1month" className={styles.customLabel} >1-3 months </label>
          </div>
          <div className={styles.checkboxGroup}>
            <input type="radio" id="3months" name="timeline" className={styles.hiddenCheckbox} value='3 - 6 months' onChange={handleRadioChange} checked={data.timeline === '3 - 6 months'} />
            <label htmlFor="3months" className={styles.customLabel} >3 - 6 months </label>
          </div>
          <div className={styles.checkboxGroup}>
            <input type="radio" id="6months" name="timeline" className={styles.hiddenCheckbox} value='6+months' onChange={handleRadioChange} checked={data.timeline === '6+months'} />
            <label htmlFor="6months" className={styles.customLabel} >6+months  </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
