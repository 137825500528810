import React, { useState } from "react";
import styles from "./styles.module.css";
import logo from "./img.png";
import axios from "axios";

const PasswordModal = () => {
  const [email, setEmail] = useState("");
  const [passwordAdmin, setPasswordAdmin] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("passwordds don't match ");
      return;
    }
    try {
      const url = `${process.env.REACT_APP_API}api/Trainer/changepassword`;

      const response = await axios.post(url, {
        email,
        passwordAdmin,
        newPassword,
        confirmPassword,
      });

      if (response.status === 200) {
        setSuccess("Password changed successfully");
        setError(null);
         window.location = "/profile/edit";
      } else {
        setError("something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div>
          <h2 className={styles.header}>Reset your password</h2>
          <p className={styles.underline}></p>
        </div>
        <img className={styles.img} src={logo} alt="Logo" />
      </div>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.inputGroup}>
          <label className={styles.label}>Email</label>
          <input
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Email"
            className={styles.input}
          />
        </div>
        <div className={styles.inputGroup}>
          <label className={styles.label}>Current password</label>
          <input
            onChange={(e) => setPasswordAdmin(e.target.value)}
            type="password"
            placeholder="Current password"
            className={styles.input}
          />
        </div>
        <div className={styles.inputGroup}>
          <label className={styles.label}>New password</label>
          <input
            onChange={(e) => setNewPassword(e.target.value)}
            type="password"
            placeholder="Password"
            className={styles.input}
          />
        </div>
        <div className={styles.inputGroup}>
          <label className={styles.label}>Confirm password</label>
          <input
            onChange={(e) => setConfirmPassword(e.target.value)}
            type="password"
            placeholder="Confirm Password"
            className={styles.input}
          />
        </div>
        <button type="submit" className={styles.button}>
          Change password
        </button>
      </form>
    </div>
  );
};

export default PasswordModal;
