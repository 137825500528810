import quizLogo from "../../../assets/quiz_logo.svg";
import styles from "./styles.module.css";

const QuizLogo = () => {
  return (
    <div className={styles.logo}>
      <img src={quizLogo} alt="logo" />
      <p>All questions copyright 2024</p>
    </div>
  );
};

export default QuizLogo;
