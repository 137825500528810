import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { request } from "../../../core/api/request";

const useFetchQuizDetails = () => {
  const { quizId } = useParams();
  const [quizDetails, setQuizDetails] = useState();

  useEffect(() => {
    request.read(`/quiz/${quizId}`).then((data) => setQuizDetails(data));
  }, [quizId]);

  return {
    quizDetails
  };
};

export default useFetchQuizDetails;
