import React from "react";
import Button from "../../../shared/components/button";
import expiredImg from "../../assets/expired.svg";
import QuizLogo from "../components/QuizLogo";
import styles from "./styles.module.css";

const TimeExpired = () => {
  return (
    <React.Fragment>
      <div className={styles.background_container}>
        <div className="appWrapper" style={{ height: "100%" }}>
          <QuizLogo />
          <div className={styles.container}>
            <p className={styles.title}>Prodcut Owner Open</p>
          </div>
        </div>
      </div>

      <div className="appWrapper">
        <div className={styles.container}>
          <div className={styles.content}>
            <img src={expiredImg} alt="warning" />
            <div className={styles.box}>
              <p>
                Your Session Has Expired. Please Go Back To <br /> The Test To
                Try Again.
              </p>
              <Button className={styles.button} text="start" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TimeExpired;
