import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { request } from "../../core/api/request";

const QuizzesList = () => {
  const [quizzesList, setQuizesList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    request
      .list("/quiz/public")
      .then((data) => setQuizesList(data.data))
      .catch(() => toast.error("Something went wrong!"));
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center"
      }}
    >
      {quizzesList?.map((quiz) => (
        <div
          style={{
            width: "200px",
            padding: "5px 10px",
            borderRadius: "10px",
            boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.28)",
            margin: "20px",
            cursor: "pointer"
          }}
          onClick={() => navigate(`/quiz/details/${quiz._id}/1`)}
        >
          <img
            src={`${process.env.REACT_APP_API}${
              quiz?.image?.filePath
            }?t=${new Date().getTime()}`}
            width={"50px"}
            alt="quiz"
          />

          <h1>{quiz.title}</h1>
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              "-webkit-line-clamp": "2",
              "-webkit-box-orient": "vertical"
            }}
          >
            {quiz.description}
          </p>
        </div>
      ))}
    </div>
  );
};

export default QuizzesList;
