import React from "react";
import Button from "../../../../shared/components/button";
import quizLock from "../../../assets/quiz_lock.svg";
import useNavigateStep from "../../hooks/useNavigateStep";
import QuizLogo from "../QuizLogo";
import styles from "./styles.module.css";

const QuizDetailsSecond = ({ quizDetails }) => {
  const { handleNavigateNext, handleNavigatePrevious } = useNavigateStep();

  return (
    <div className={styles.background_container}>
      <div className="appWrapper">
        <div className={styles.container}>
          <QuizLogo />
          <div className={styles.group}>
            <div className={styles.content}>
              <div className={styles.details}>
                <p className={styles.title}>{quizDetails?.title}</p>
                <p className={styles.description}>
                  No part of this assessment may be reproduced, distributed, or
                  transmitted in any form or by any means, including
                  photocopying, recording, or other electronic or mechanical
                  methods, without the prior written permission of Scrum.org.
                  Violation of the copyright may result in legal action against
                  you and your IP being blocked from the test host platform.
                  Copyright © 2024 Scrum.org.  All rights reserved.
                </p>
              </div>
              <div className={styles.buttons}>
                <Button
                  onClick={handleNavigatePrevious}
                  className={styles.button}
                  text="Previous"
                />
                <Button
                  className={styles.button}
                  onClick={handleNavigateNext}
                  text="Continue"
                />
              </div>
            </div>

            <div className={styles.image}>
              <img src={quizLock} alt="quiz" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizDetailsSecond;
