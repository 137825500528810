import {
  AccountCircle as AccountCircleIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  HistoryEdu as HistoryEduIcon,
  OndemandVideo as OndemandVideoIcon,
  Settings as SettingsIcon,
  SupervisorAccount as SupervisorAccountIcon
} from "@mui/icons-material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import ListAltIcon from "@mui/icons-material/ListAlt";
import QuizIcon from "@mui/icons-material/Quiz";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MaterialUISwitch } from "./elements.js";
import PrimarySearchAppBar from "./nav";
import styles from "./styles.module.css";

const Layout = ({ children, setActiveSection }) => {
  const [collapseStates, setCollapseStates] = useState({
    candidats: false,
    trainers: false,
    trainings: false,
    courses: false,
    quiz: false
  });

  const handleSetSection = (section) =>
    setActiveSection((prev) => ({ ...prev, path: section }));

  const toggleCollapse = (section) => {
    setCollapseStates((prev) => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  return (
    <React.Fragment>
      <main className={styles.MainSection}>
        <section className={styles.leftSection}>
          <nav className={styles.leftSectionNav}>
            <Link to="/">
              <p className={`${styles.logo}`}>U!NOW</p>
            </Link>
            <FormControlLabel
              control={<MaterialUISwitch sx={{ m: 1 }} />}
              label=""
            />
          </nav>
          <div>
            <List
              sx={{ width: "100%", m: 0, bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Managment
                </ListSubheader>
              }
            >
              <Button
                sx={{ width: "200px", margin: "5px 50px" }}
                onClick={() => handleSetSection("dashboard")}
                variant="contained"
                startIcon={<LineAxisIcon />}
              >
                Dashboard
              </Button>

              <SectionList
                title="Candidats"
                icon={<AccountCircleIcon />}
                open={collapseStates.candidats}
                onToggle={() => toggleCollapse("candidats")}
                items={[
                  {
                    label: "Afficher",
                    icon: <ListAltIcon />,
                    onClick: () => handleSetSection("showCandidats")
                  }
                ]}
              />
              <SectionList
                title="Formateurs"
                icon={<SupervisorAccountIcon />}
                open={collapseStates.trainers}
                onToggle={() => toggleCollapse("trainers")}
                items={[
                  {
                    label: "Afficher",
                    icon: <ListAltIcon />,
                    onClick: () => handleSetSection("showTrainers")
                  },
                  {
                    label: "Ajouter",
                    icon: <AddCircleOutlineIcon />,
                    onClick: () => handleSetSection("addTrainer")
                  }
                ]}
              />

              <SectionList
                title="Trainings"
                icon={<HistoryEduIcon />}
                open={collapseStates.trainings}
                onToggle={() => toggleCollapse("trainings")}
                items={[
                  {
                    label: "Afficher",
                    icon: <ListAltIcon />,
                    onClick: () => handleSetSection("showTrainings")
                  },
                  {
                    label: "Ajouter",
                    icon: <AddCircleOutlineIcon />,
                    onClick: () => handleSetSection("addTraining")
                  }
                ]}
              />

              <SectionList
                title="Courses"
                icon={<OndemandVideoIcon />}
                open={collapseStates.courses}
                onToggle={() => toggleCollapse("courses")}
                items={[
                  {
                    label: "Afficher",
                    icon: <ListAltIcon />,
                    onClick: () => handleSetSection("showCourses")
                  },
                  {
                    label: "Ajouter",
                    icon: <AddCircleOutlineIcon />,
                    onClick: () => handleSetSection("addCourse")
                  }
                ]}
              />

              <ListItemButton onClick={() => handleSetSection("quizzes")}>
                <ListItemIcon>
                  <QuizIcon />
                </ListItemIcon>
                <ListItemText primary="Quizzes" />
              </ListItemButton>

              <ListItemButton
                onClick={() => handleSetSection("showCategories")}
              >
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary="Categories" />
              </ListItemButton>

              <ListItemButton onClick={() => handleSetSection("settings")}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItemButton>
            </List>
          </div>
        </section>
        <section className={styles.rightSection}>
          <nav>
            <PrimarySearchAppBar />
          </nav>
          <main className={styles.ComponentContainer}>{children}</main>
        </section>
      </main>
    </React.Fragment>
  );
};

export default Layout;

const SectionList = ({ title, icon, open, onToggle, items }) => (
  <>
    <ListItemButton onClick={onToggle}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title} />
      {open ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {items.map(({ label, icon, onClick }, index) => (
          <ListItemButton key={index} onClick={onClick} sx={{ pl: 4 }}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={label} />
          </ListItemButton>
        ))}
      </List>
    </Collapse>
  </>
);
