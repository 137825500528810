import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

export const usePersonalize = (userId) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    stepPersonalize_1: { interests: [''], exploreFirst: [] },
    stepPersonalize_2: { goals: [''], timeline: '' },
    stepPersonalize_3: { availability: [''], hoursperweek: '', learningother: '' },
    stepPersonalize_4: { learningpace: [''], dayslearning: '', timeOfDay: '' },
  });
  const navigate = useNavigate();
  const [profileCompleted, setProfileCompleted] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const candidateResponse = await axios.get(`${process.env.REACT_APP_API}api/candidat/candidates/${userId}`);
        const candidateData = candidateResponse.data;
        setFormData((prevData) => ({ ...prevData, ...candidateData }));
        setProfileCompleted(candidateData.profileCompleted);
      } catch (error) {
        console.error("Error fetching candidate data:", error);
      }
    };
    fetchData();
  }, [userId]);


  const isStepComplete = (stepData) => {
    const currentStepData = stepData[`stepPersonalize_${currentStep + 1}`];
    if (!currentStepData) return false;
    switch (currentStep) {
      case 0:
        return (
          Array.isArray(currentStepData.interests) &&
          currentStepData.interests.length > 0 &&
          Array.isArray(currentStepData.exploreFirst) &&
          currentStepData.exploreFirst.length > 0 &&
          currentStepData.exploreFirst.every(item => item.interest && item.interest.trim() !== '' && item.level && item.level.trim() !== '')

        );
      case 1:
        return Array.isArray(currentStepData.goals) && currentStepData.goals.length > 0 &&
          typeof currentStepData.timeline === 'string' && currentStepData.timeline.trim() !== '';
      case 2:
        return Array.isArray(currentStepData.availability) && currentStepData.availability.length > 0 &&
          typeof currentStepData.hoursperweek === 'string' && currentStepData.hoursperweek.trim() !== '' &&
          typeof currentStepData.learningother === 'string' && currentStepData.learningother.trim() !== '';
      case 3:
        return Array.isArray(currentStepData.learningpace) && currentStepData.learningpace.length > 0 &&
          typeof currentStepData.dayslearning === 'string' && currentStepData.dayslearning.trim() !== '' &&
          typeof currentStepData.timeOfDay === 'string' && currentStepData.timeOfDay.trim() !== '';
      default:
        return false;
    }
  };

  const cleanStepData = (stepData) => {
    Object.keys(stepData).forEach((key) => {
      if (Array.isArray(stepData[key])) {
        if (key === 'exploreFirst') {
          stepData[key] = stepData[key].filter(
            item => item.interest && item.interest.trim() !== '' && item.level && item.level.trim() !== ''
          );
        } else {
          stepData[key] = stepData[key].filter(item => item.trim() !== '');
        }
      }
    });
    return stepData;
  };

  const saveStepData = async () => {
    let stepData = {};
    let apiUrl;
    const currentStepKey = `stepPersonalize_${currentStep + 1}`;
    cleanStepData(formData[currentStepKey]);
    switch (currentStep) {
      case 0:
        apiUrl = `${process.env.REACT_APP_API}api/candidat/step1/${userId}`;
        stepData = { stepPersonalize_1: formData.stepPersonalize_1 };
        break;
      case 1:
        apiUrl = `${process.env.REACT_APP_API}api/candidat/step2/${userId}`;
        stepData = { stepPersonalize_2: formData.stepPersonalize_2 };
        break;
      case 2:
        apiUrl = `${process.env.REACT_APP_API}api/candidat/step3/${userId}`;
        stepData = { stepPersonalize_3: formData.stepPersonalize_3 };
        break;
      case 3:
        apiUrl = `${process.env.REACT_APP_API}api/candidat/step4/${userId}`;
        stepData = { stepPersonalize_4: formData.stepPersonalize_4 };
        break;
      default:
        return;
    }

    const completedStep = isStepComplete(formData[currentStepKey]);
    if (completedStep) {
      stepData.incrementProgress = 20;
    }

    try {
      await axios.patch(apiUrl, stepData);
      if (completedStep) {
        setProfileCompleted((prev) => prev + 25);
      }
    } catch (error) {
      console.error("Error saving step data:", error);
    }
  };

  const handleNext = async () => {
    await saveStepData();
    if (currentStep < 3) {
      setCurrentStep((prev) => prev + 1);
    }
    else {
      setCurrentStep(3);
      navigate('/candidate/profile');
    }
  };

  const handleBack = () => {
    if (currentStep > 0) setCurrentStep((prev) => prev - 1);
  };
  const goToHome = () => {
    navigate("/")
  }
  return {
    currentStep,
    formData,
    setFormData,
    profileCompleted,
    handleNext,
    handleBack,
    isStepComplete,
    goToHome
  };
};
