import React from "react";
import toast from "react-hot-toast";
import { request } from "../../../../../core/api/request";
import Button from "../../../../../shared/components/button";
import styles from "./styles.module.css";

const DeleteModal = ({ setDeleteModal, deleteModal }) => {
  const handleDelete = () => {
    request.remove(`/quiz/question`, deleteModal.questionId).then(() => {
      toast.success("Question deleted successfully!");
      setDeleteModal({ open: false, questionId: null });
    });
  };

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <p>Are you sure you want to delete this question?</p>
        <div className={styles.buttons}>
          <Button
            varaint="outline"
            onClick={() => setDeleteModal({ open: false, questionId: null })}
            className={styles.button}
            text="No"
          />
          <Button onClick={handleDelete} className={styles.button} text="Yes" />
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
