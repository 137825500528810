import styles from "./styles.module.css";

const Input = ({
  label,
  name,
  placeholder,
  type = "text",
  style,
  value,
  onChange,
  onClick,
  required,
  className,
  ...props
}) => {
  return (
    <div className={`${styles.inputContainer}  ${className}`} onClick={onClick}>
      {label && <label>{label}</label>}
      <input
        {...props}
        style={style}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
      />
    </div>
  );
};

export default Input;
