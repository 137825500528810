import React, { useEffect, useState } from "react";
import { useQuiz } from "../../../../hooks/QuizContext";
import HeaderRow from "./HeaderRow";
import QuestionRow from "./QuestionRow";
import styles from "./styles.module.css";

const QuestionModal = ({ openModal, setOpenModal }) => {
  const { quiz } = useQuiz();
  const [currentOpen, setCurrentOpen] = useState("questions");
  const [questions, setQuestions] = useState(quiz.questions || []);

  useEffect(() => {
    if (currentOpen === "bookmarks") {
      setQuestions(quiz?.questions.filter((q) => q.bookmarked === true));
    }

    if (currentOpen === "questions") {
      setQuestions(quiz?.questions);
    }
  }, [currentOpen, quiz?.questions]);

  return (
    <div className={`${styles.modal} ${openModal ? styles.active : ""}`}>
      <HeaderRow
        currentOpen={currentOpen}
        setCurrentOpen={setCurrentOpen}
        setOpenModal={setOpenModal}
      />
      {questions?.map((question, index) => (
        <QuestionRow
          key={index}
          question={question}
          setOpenModal={setOpenModal}
          index={index}
        />
      ))}
    </div>
  );
};

export default QuestionModal;
