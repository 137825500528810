import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import React, { useRef } from "react";
import styles from "./styles.module.css";

const Upload = ({ fileUpload, setFileUpload }) => {
  const ref = useRef(null);

  return (
    <div className={styles.imgUpload} onClick={() => ref.current.click()}>
      {fileUpload && (
        <img
          className={styles.img}
          src={
            fileUpload instanceof File
              ? URL.createObjectURL(fileUpload)
              : fileUpload
          }
          alt=""
        />
      )}
      <AddPhotoAlternateIcon style={{ color: "#ccc" }} />
      <input
        ref={ref}
        style={{ display: "none" }}
        type="file"
        accept="image/*"
        onChange={(e) => setFileUpload(e.target.files[0])}
      />
    </div>
  );
};

export default Upload;
