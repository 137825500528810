import React from "react";
import Button from "../../../../shared/components/button";
import Nav from "../../../Nav";
import useNavigateStep from "../../hooks/useNavigateStep";
import styles from "./styles.module.css";

const QuizDetailsFirst = ({ quizDetails }) => {
  const { handleNavigateNext } = useNavigateStep();

  return (
    <React.Fragment>
      <div className={styles.background_container}>
        <div className="appWrapper" style={{ height: "100%" }}>
          <Nav />
          <div className={styles.container}>
            <div className={styles.content}>
              <p className={styles.title}>{quizDetails?.title} </p>
              <p className={styles.description}>
                Assess Your Knowledge Of The Product Owner Accountabilities
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="appWrapper">
        <div className={styles.container}>
          <div className={styles.details}>
            <div className="breadcrumb-container">
              <div className="breadcrumb-header">
                <button className="breadcrumb-text">Home</button>
                <img src="/svg/polygon.svg" alt="Breadcrumb separator" />
                <button className="breadcrumb-text">Open Assessment</button>
                <img src="/svg/polygon.svg" alt="Breadcrumb separator" />
                <button className="breadcrumb-text">Product Owner Open</button>
              </div>
            </div>

            <div className={styles.text}>
              <p>
                The Product Owner Open assessment is a tool for validating your
                basic knowledge of the Product Owner accountabilities within the
                Scrum framework. Taking the Product Owner Open assessment will
                allow you to create a baseline of your current knowledge, from
                which you can start improving immediately.  This assessment is
                free and does not include  any certification.  
              </p>

              <p>
                The assessment is designed to assess one's understanding of
                Product Backlog management and maximizing value of a product. It
                is one useful tool for those preparing to take the{" "}
                <span> Professional Scrum Product Owner I</span> certification
                test. Before attempting the Product Owner Open assessment it is
                highly recommended that you review the <span>Scrum Guide</span>.
                 One way to improve is through our{" "}
                <span>Professional Scrum Product Owner I</span> training class
                and online <span>Product Owner Learning Path</span>.  
              </p>

              <p>
                The assessment consists of 15 questions randomly selected from a
                larger pool.
              </p>
            </div>

            <div className={styles.buttons}>
              <Button
                className={styles.button}
                varaint="outline"
                text="Go Back"
                onClick={() => window.history.back()}
              />
              <Button
                className={styles.button}
                onClick={handleNavigateNext}
                text="Start Assessment"
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default QuizDetailsFirst;
