import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { request } from "../../../core/api/request";
import { useQuiz } from "../../../hooks/QuizContext";

const useQuestionDetails = () => {
  const {
    quiz,
    updateQuizAnswers,
    currentQuestion,
    setCurrentQuestion,
    handleBookmark
  } = useQuiz();
  const navigate = useNavigate();
  const [question, setQuestion] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (currentQuestion) {
      request.read(`/quiz/question/${currentQuestion.id}`).then((data) => {
        setQuestion(data);
      });
      setSelectedAnswers(
        quiz.questions?.[currentQuestion.number].answers || []
      );
    }
  }, [currentQuestion, quiz.questions]);

  const isFirstQuestion = quiz?.questions?.[0]?.question === currentQuestion.id;
  const isFinalQuestion =
    quiz?.questions?.[quiz.questions.length - 1]?.question ===
    currentQuestion.id;

  const handleNextQuestion = () => {
    const NumberNextQuestion = +currentQuestion.number + 1;
    if (NumberNextQuestion > quiz.questions?.length - 1) return;

    if (selectedAnswers.length === 0) {
      setOpen(true);
      return;
    }

    if (selectedAnswers.length !== 0) {
      request.update(
        `/quiz/candidatAnswer/${quiz._id}`,
        currentQuestion.id,
        {
          answers: selectedAnswers
        },
        "PUT",
        false
      );
      updateQuizAnswers(selectedAnswers);
    }

    setCurrentQuestion({
      id: quiz.questions[NumberNextQuestion].question,
      number: NumberNextQuestion
    });
    setSelectedAnswers([]);
  };

  const handlePreviousQuestion = () => {
    const NumberNextQuestion = +currentQuestion.number - 1;
    if (NumberNextQuestion < 0) return;
    setCurrentQuestion({
      id: quiz.questions[NumberNextQuestion].question,
      number: NumberNextQuestion
    });
    setSelectedAnswers([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedAnswers.length === 0) {
      setOpen(true);
      return;
    }

    setLoading(true);
    request
      .update(
        `/quiz/candidatAnswer/${quiz._id}`,
        currentQuestion.id,
        {
          answers: selectedAnswers
        },
        "PUT"
      )
      .then(() => {
        request
          .update("/quiz/candidatAnswer/submit", quiz._id, {
            submitDate: new Date()
          })
          .then(() => {
            sessionStorage.clear();
            navigate(`/quiz/result/${quiz._id}`);
          })
          .finally(() => setLoading(false));
      });
  };

  const handleAnswerChange = (e) => {
    const value = e.target.value;

    setSelectedAnswers((prev) => {
      if (question.answersCount === 1) return [value];
      const exists = prev.includes(value);
      const updatedAnswers = exists
        ? prev.filter((item) => item !== value)
        : [...prev, value];

      return updatedAnswers.length <= question.answersCount
        ? updatedAnswers
        : prev;
    });
  };

  return {
    question,
    selectedAnswers,
    isFirstQuestion,
    isFinalQuestion,
    handleAnswerChange,
    handleNextQuestion,
    handlePreviousQuestion,
    handleSubmit,
    handleBookmark,
    currentQuestion,
    setOpen,
    loading,
    open,
    quiz
  };
};

export default useQuestionDetails;
