import React from "react";
import QuizDetailsFirst from "../components/QuizDetailsFirst";
import QuizDetailsSecond from "../components/QuizDetailsSecond";
import QuizDetailsThird from "../components/QuizDetailsThird";
import useFetchQuizDetails from "../hooks/useFetchQuiz";
import useNavigateStep from "../hooks/useNavigateStep";

const QuizDetails = () => {
  const { stepNumber } = useNavigateStep();
  const { quizDetails } = useFetchQuizDetails();

  const renderStep = () => {
    switch (stepNumber) {
      case 1:
        return <QuizDetailsFirst quizDetails={quizDetails} />;
      case 2:
        return <QuizDetailsSecond quizDetails={quizDetails} />;
      case 3:
        return <QuizDetailsThird quizDetails={quizDetails} />;
      default:
        return <QuizDetailsFirst quizDetails={quizDetails} />;
    }
  };

  return <div>{renderStep()}</div>;
};

export default QuizDetails;
