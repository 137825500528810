import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { request } from "../../../core/api/request";
import { getDurationFormatted } from "../../../shared/dateUtils";
import badgeImg from "../../assets/congra_badge.svg";
import congraImg from "../../assets/congratulations.svg";
import failedImg from "../../assets/failed.svg";
import noteImg from "../../assets/note.svg";
import QuizLogo from "../components/QuizLogo";
import styles from "./styles.module.css";

const Result = () => {
  const { id } = useParams();
  const [result, setResult] = useState({});

  useEffect(() => {
    request.read(`/quiz/candidatAnswer/result`, id).then((data) => {
      setResult({
        ...data,
        startTime: new Date(data.startTime),
        endTime: new Date(data.endTime)
      });
    });
  }, [id]);

  return (
    <React.Fragment>
      <div className={styles.background_container}>
        <div className="appWrapper" style={{ height: "100%" }}>
          <Link to="/">
            <QuizLogo />
          </Link>
        </div>
      </div>

      <div className="appWrapper">
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.box}>
              <div className={styles.group}>
                <img src={noteImg} alt="warning" />
                <div className={styles.stack}>
                  <p>
                    <span className={styles.note}>Note:</span> This Test was
                    automatically finished when either:
                  </p>

                  <ul className={styles.decimal}>
                    <li> Its time limit was reached when taking the Test.</li>
                    <li>
                      An attempt to resume this Test was not allowed due to date
                      or time limits
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className={styles.box}>
              <div className={styles.stack}>
                <p className={styles.title}>Product Owner Open</p>

                <ul className={styles.list}>
                  <li>
                    <p>
                      Percentage: <span>{result?.score?.toFixed(2)}%</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Duration :{" "}
                      <span>
                        {getDurationFormatted({
                          startTime: result?.startTime,
                          endTime: result?.endTime
                        })}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Date started:{" "}
                      <span>
                        {result?.startTime &&
                          format(result?.startTime, "eee d MMM ''yy HH:mm")}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Date finished:{" "}
                      <span>
                        {result?.startTime &&
                          format(result?.endTime, "eee d MMM ''yy HH:mm")}
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
              <div className={styles.images}>
                {result.passed && (
                  <>
                    <img
                      className={styles.congra}
                      src={congraImg}
                      alt="congradulations"
                    />
                    <img
                      className={styles.badge}
                      src={badgeImg}
                      alt="congradulations badge"
                    />
                  </>
                )}

                {!result.passed && (
                  <div className={styles.failed}>
                    <p>
                      Sometimes, Even <span>Geniuses</span> Make Mistakes
                    </p>
                    <img src={failedImg} alt="failed" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Result;
