import React from "react";
import Input from "../../../../../shared/components/Inputs/Input";
import Select from "../../../../../shared/components/Inputs/Select";
import Button from "../../../../../shared/components/button/index";
import ImgUploadSection from "../../../../ImgUploadSection";
import styles from "./styles.module.css";

const QuizModal = ({
  formData,
  img,
  setImg,
  searchTerm,
  searchResults,
  isEditMode,
  handleSubmit,
  cancelForm,
  handleSearch,
  handleSelectRelatedId,
  handleChange
}) => {
  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <form onSubmit={handleSubmit}>
          <ImgUploadSection
            SingleFileChange={(e) => setImg(e.target.files[0])}
            img={img}
            imgSize={100}
            iconBgSize={30}
            iconSize={15}
          />
          <Input
            className={styles.inputForm}
            label="Quiz Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
          <Input
            className={styles.inputForm}
            label="Quiz Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
          />
          <Input
            className={styles.inputForm}
            type="number"
            name="numberOfQuestions"
            label="Number of questions"
            value={formData.numberOfQuestions}
            onChange={handleChange}
            required
          />
          <Input
            className={styles.inputForm}
            type="number"
            name="time"
            label="Time in minutes"
            value={formData.time}
            onChange={handleChange}
            required
          />
          <Input
            className={styles.inputForm}
            type="number"
            name="passingScore"
            label="Passing score"
            value={formData.passingScore}
            onChange={handleChange}
            required
          />
          <Select
            name="related"
            label="Quiz Related"
            className={styles.inputForm}
            options={["course", "training", "simulated-exam"]}
            value={formData.related}
            onChange={handleChange}
            required
          />
          {formData.related && formData.related !== "simulated-exam" && (
            <div style={{ position: "relative", width: "100%" }}>
              <Input
                className={styles.inputForm}
                placeholder={`Search ${formData.related}`}
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <div
                className={
                  searchResults?.data.length > 0
                    ? styles.searchResultsContainer
                    : ""
                }
              >
                {searchResults.data?.length > 0
                  ? searchResults.data.map((item) => (
                      <div
                        key={item._id}
                        className={styles.resultItem}
                        onClick={() => handleSelectRelatedId(item)}
                      >
                        <span className={styles.resultItemText}>
                          {item.Title}
                        </span>
                      </div>
                    ))
                  : searchResults?.isVisible && (
                      <div className={styles.noResults}>No results found</div>
                    )}
              </div>
            </div>
          )}

          <div className={styles.buttons}>
            <Button variant="outline" onClick={cancelForm} text="Cancel" />
            <Button
              type="submit"
              text={isEditMode ? "Update Quiz" : "Add Quiz"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default QuizModal;
