import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import CloseIcon from "@mui/icons-material/Close";
import React, { useMemo } from "react";
import { useQuiz } from "../../../../hooks/QuizContext";
import styles from "./styles.module.css";

const HeaderRow = ({ currentOpen, setCurrentOpen, setOpenModal }) => {
  const { quiz } = useQuiz();

  const bookmarkedQuestions = useMemo(() => {
    return quiz.questions?.filter((q) => q.bookmarked === true).length || 0;
  }, [quiz.questions]);

  return (
    <React.Fragment>
      <div className={styles.row}>
        <div className={styles.buttons}>
          <button
            className={currentOpen === "questions" ? styles.active : ""}
            onClick={() => setCurrentOpen("questions")}
          >
            ALL Questions
          </button>
          <hr />
          <button
            className={currentOpen === "bookmarks" ? styles.active : ""}
            onClick={() => setCurrentOpen("bookmarks")}
          >
            <BookmarkBorderOutlinedIcon
              style={{
                color:
                  currentOpen === "bookmarks"
                    ? "var(--primary-color)"
                    : "#d6d6d6"
              }}
            />
            Bookmarks <span>{bookmarkedQuestions}</span>
          </button>
        </div>
        <div className={styles.arrow}>
          <CloseIcon
            onClick={() => setOpenModal(false)}
            style={{
              transform: "rotate(180deg)",
              padding: "3px",
              color: "var(--secondary-color)"
            }}
          />
        </div>
      </div>
      <hr />
    </React.Fragment>
  );
};

export default HeaderRow;
