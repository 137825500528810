import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { request } from "../../../../core/api/request";
import useDebouncedState from "../../../../hooks/useDebouncedState";

const useQuestions = (quizId) => {
  const [openQuestionModal, setOpenQuestionModal] = useState(false);
  const [questionEditing, setQuestionEditing] = useState(null);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [search, setSearch] = useDebouncedState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 10;

  const [deleteModal, setDeleteModal] = useState({
    open: false,
    questionId: null
  });

  const fetchQuestions = useCallback(
    (page = 1, skipLoading = false) => {
      if (!skipLoading) setLoading(true);

      request
        .list(`/quiz/question/list/${quizId}`, {
          limit: itemsPerPage,
          page,
          search
        })
        .then((data) => {
          setQuestions(data.questions);
          setTotalPages(data.totalPages);
          setCurrentPage(data.currentPage);
        })
        .catch(() => toast.error("Failed to fetch questions"))
        .finally(() => {
          setLoading(false);
        });
    },
    [quizId, search]
  );

  useEffect(() => {
    fetchQuestions(
      currentPage,
      openQuestionModal || openImportModal || deleteModal.open
    );
  }, [
    fetchQuestions,
    currentPage,
    deleteModal.open,
    openQuestionModal,
    openImportModal
  ]);

  const handlePageChange = (_, newPage) => {
    setCurrentPage(newPage);
    fetchQuestions(newPage);
  };

  const handleEdit = (question) => {
    setQuestionEditing(question);
    setOpenQuestionModal(true);
  };

  const handleDelete = (id) => {
    setDeleteModal({ open: true, questionId: id });
  };

  return {
    openQuestionModal,
    setOpenQuestionModal,
    questionEditing,
    setQuestionEditing,
    questions,
    search,
    setSearch,
    currentPage,
    totalPages,
    handlePageChange,
    loading,
    handleDelete,
    setDeleteModal,
    deleteModal,
    setOpenImportModal,
    openImportModal,
    handleEdit
  };
};

export default useQuestions;
