import AddIcon from "@mui/icons-material/Add";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import React from "react";
import Loading from "../../../../component/Loading/index";
import Button from "../../../../shared/components/button";
import Input from "../../../../shared/components/Inputs/Input";
import DeleteModal from "../components/DeleteModal";
import ImportModal from "../components/ImportModal";
import QuestionModal from "../components/QuestionModal";
import useQuestions from "../hooks/useQuestions";
import styles from "./styles.module.css";

const Question = ({ activeSection }) => {
  const {
    openQuestionModal,
    setOpenQuestionModal,
    questionEditing,
    setQuestionEditing,
    questions,
    search,
    setSearch,
    currentPage,
    totalPages,
    handlePageChange,
    loading,
    handleEdit,
    deleteModal,
    handleDelete,
    setOpenImportModal,
    openImportModal,
    setDeleteModal
  } = useQuestions(activeSection.params.quizId);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Input
          placeholder="Question..."
          style={{ maxWidth: "300px", height: "30px" }}
          defaultValue={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className={styles.buttons}>
          <Button
            onClick={() => setOpenQuestionModal(true)}
            text="Add Question"
          />
          <Button
            onClick={() => setOpenImportModal(true)}
            varaint={"outline"}
            leftIcon={<AddIcon />}
            text="Import"
          />
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="questions table">
          <TableHead>
            <TableRow>
              <TableCell>Questions</TableCell>
              <TableCell>Choices Number</TableCell>
              <TableCell>Answer Count</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell colSpan={4}>
                  <Loading h="55vh" />
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              questions?.map((question) => (
                <TableRow key={question._id}>
                  <TableCell>{question.questionText}</TableCell>
                  <TableCell>{question.choices.length}</TableCell>
                  <TableCell>{question.answersCount}</TableCell>
                  <TableCell className={styles.actions}>
                    <BorderColorIcon onClick={() => handleEdit(question)} />
                    <DeleteIcon onClick={() => handleDelete(question._id)} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        className={styles.pagination}
      />
      {openQuestionModal && (
        <QuestionModal
          setOpenModal={setOpenQuestionModal}
          quizId={activeSection.params.quizId}
          questions={questions}
          questionEditing={questionEditing}
          setQuestionEditing={setQuestionEditing}
        />
      )}
      {deleteModal.open && (
        <DeleteModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
        />
      )}
      {openImportModal && (
        <ImportModal
          quizId={activeSection.params.quizId}
          setOpenModal={setOpenImportModal}
        />
      )}
    </div>
  );
};

export default Question;
