export const getDurationFormatted = ({ startTime, endTime, duration }) => {
  let durationMs;

  if (startTime && endTime) {
    durationMs = new Date(endTime) - new Date(startTime);
  }

  if (duration) {
    durationMs = duration * 60 * 1000;
  }

  const hours = String(Math.floor(durationMs / (1000 * 60 * 60))).padStart(
    2,
    "0"
  );
  const minutes = String(
    Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60))
  ).padStart(2, "0");
  const seconds = String(
    Math.floor((durationMs % (1000 * 60)) / 1000)
  ).padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
};
