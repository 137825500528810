import AddIcon from "@mui/icons-material/Add";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import React from "react";
import Input from "../../../shared/components/Inputs/Input";
import Button from "../../../shared/components/button/index";
import Loading from "../../Loading";
import PaginationComponent from "../../Pagination";
import QuizModal from "./components/QuizModal";
import useQuiz from "./hooks/useQuiz";
import styles from "./styles.module.css";

const statusColor = {
  private: "var(--secondary-color)",
  public: "var(--primary-color)"
};

const Quiz = ({ setActiveSection }) => {
  const {
    quiz,
    search,
    setOpen,
    open,
    currentPage,
    totalPages,
    isEditMode,
    searchTerm,
    img,
    searchResults,
    setSearch,
    formData,
    setFormData,
    setImg,
    handleSearch,
    handleSelectRelatedId,
    handleChange,
    handleSubmit,
    handleEditClick,
    handlePageChange,
    loading,
    cancelForm
  } = useQuiz();

  return (
    <div className={styles.container}>
      <div className={styles.input}>
        <Input
          style={{ height: "30px", maxWidth: "300px" }}
          onChange={(e) => setSearch(e.target.value)}
          defaultValue={search}
          placeholder="Quiz Title"
          text="Quiz Name"
        />
        <Button
          onClick={() => setOpen(true)}
          leftIcon={<AddIcon style={{ width: "20px" }} />}
          text="Add Quiz"
        />
      </div>

      {loading && <Loading h="55vh" />}
      <div className={styles.content}>
        {!loading &&
          quiz?.map((quiz) => (
            <div key={quiz._id} className={styles.box}>
              <img
                src={`${process.env.REACT_APP_API}${
                  quiz?.image?.filePath
                }?t=${new Date().getTime()}`}
                alt="quiz"
              />

              <div
                className={styles.details}
                onClick={() =>
                  setActiveSection({
                    path: "questions",
                    params: { quizId: quiz._id }
                  })
                }
              >
                <div className={styles.group}>
                  <p className={styles.title}>{quiz?.title}</p>
                  <p
                    className={styles.status}
                    style={{
                      backgroundColor:
                        statusColor[quiz?.public ? "public" : "private"]
                    }}
                  >
                    {quiz?.public ? "Public" : "Private"}
                  </p>
                </div>
                <p className={styles.questionNumber}>
                  <span>Questions Per Quiz</span>
                  <span>{quiz?.numberOfQuestions}</span>
                </p>
              </div>
              <div
                className={styles.edit}
                onClick={() => handleEditClick(quiz)}
              >
                <ModeEditIcon
                  fontSize="small"
                  style={{ width: "17px", color: "white" }}
                />
              </div>
            </div>
          ))}
        <div className="center" style={{ paddingTop: "2%" }}>
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
          <br />
        </div>
      </div>

      {open && (
        <QuizModal
          img={img}
          setImg={setImg}
          setFormData={setFormData}
          formData={formData}
          searchTerm={searchTerm}
          searchResults={searchResults}
          isEditMode={isEditMode}
          handleSubmit={handleSubmit}
          cancelForm={cancelForm}
          handleSearch={handleSearch}
          handleSelectRelatedId={handleSelectRelatedId}
          handleChange={handleChange}
        />
      )}
    </div>
  );
};

export default Quiz;
