import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { request } from "../../../../core/api/request";
import { useQuiz } from "../../../../hooks/QuizContext";
import Button from "../../../../shared/components/button";
import { getDurationFormatted } from "../../../../shared/dateUtils";
import QuizQuestion from "../../../assets/quiz_question.svg";
import QuizLogo from "../QuizLogo";
import styles from "./styles.module.css";

const QuizDetailsThird = ({ quizDetails }) => {
  const { quizId } = useParams();
  const { setQuiz, setCurrentQuestion } = useQuiz();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleNavigate = () => {
    sessionStorage.clear();
    setLoading(false);
    request
      .read(`/quiz/generate/${quizId}`)
      .then((data) => {
        const quiz = {
          ...data?.candidatAnswer,
          questions: data?.candidatAnswer?.questions?.map((q) => ({
            ...q,
            bookmarked: false
          }))
        };

        setQuiz(quiz);
        setCurrentQuestion({ id: quiz.questions[0].question, number: 0 });
        navigate(`/quiz/${quizId}`);
      })
      .finally(() => setLoading(true));
  };

  return (
    <React.Fragment>
      <div className={styles.background_container}>
        <div className="appWrapper" style={{ height: "100%" }}>
          <QuizLogo />
          <div className={styles.container}>
            <div className={styles.content}>
              <p className={styles.title}>{quizDetails?.title}</p>
              <div className={styles.image}>
                <img src={QuizQuestion} alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="appWrapper">
        <div className={styles.container}>
          <section className={styles.details}>
            <p className={styles.title}>Instructions</p>

            <ul className={styles.list}>
              <li>
                Number of questions:{" "}
                <span>{quizDetails?.numberOfQuestions}</span>
              </li>
              <li>
                Has a time limit of:{" "}
                <span>
                  {getDurationFormatted({ duration: quizDetails?.time })}
                </span>
              </li>
              <li>
                Must be finished in one sitting. You cannot save and finish
                later.{" "}
              </li>
              <li>
                Questions displayed per page: <span>1</span>
              </li>
              <li>Will allow you to go back and change your answers</li>
              <li>Will not let you finish with any questions unattended</li>
              <li>
                Has a pass mark of: <span>{quizDetails?.passingScore}%</span>
              </li>
            </ul>
            <Button
              text="Continue"
              loading={loading}
              className={styles.button}
              onClick={handleNavigate}
            />
          </section>
        </div>
      </div>
    </React.Fragment>
  );
};

export default QuizDetailsThird;
