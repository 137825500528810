import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuiz } from "../../../../hooks/QuizContext";
import styles from "./styles.module.css";

const Timer = () => {
  const { quiz } = useQuiz();
  const duration = +quiz?.time * 60;
  const radius = 15.9155;
  const circumference = 2 * Math.PI * radius;
  const navigate = useNavigate();

  const calculateRemainingTime = () => {
    const quizStartTime = new Date(quiz?.createdAt).getTime();
    const elpasedTime = Math.floor((Date.now() - quizStartTime) / 1000);
    const remainingTime = duration - elpasedTime;
    return Math.max(remainingTime, 0);
  };

  const [timeRemaining, setTimeRemaining] = useState(() =>
    sessionStorage.getItem("quizRemainingTime")
      ? parseInt(sessionStorage.getItem("quizRemainingTime"))
      : calculateRemainingTime()
  );

  useEffect(() => {
    if (!sessionStorage.getItem("quizRemainingTime")) {
      sessionStorage.setItem("quizRemainingTime", timeRemaining);
    }

    const interval = setInterval(() => {
      setTimeRemaining((prev) => {
        const updatedTime = prev - 1;
        sessionStorage.setItem("quizRemainingTime", updatedTime);

        if (updatedTime <= 0) {
          clearInterval(interval);
          sessionStorage.clear();
          navigate(`/quiz/timeExpired`);
          return 0;
        }
        return updatedTime;
      });
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [quiz?.time, navigate]);

  const strokeDashoffset = circumference * (1 - timeRemaining / duration);

  const formatTime = (seconds) => {
    const time = new Date(0, 0, 0, 0, 0, seconds);
    return format(time, "HH:mm:ss");
  };

  return (
    <div className={styles.timerContainer}>
      <svg
        className={styles.timer}
        width="100%"
        height="100%"
        viewBox="0 0 36 36"
      >
        <g transform="rotate(-90 18 18)">
          <circle
            className={styles.timerBg}
            cx="18"
            cy="18"
            r={radius}
          ></circle>
          <circle
            className={styles.timerProgress}
            cx="18"
            cy="18"
            r={radius}
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
          ></circle>
        </g>
        <text x="18" y="16" textAnchor="middle" className={styles.timeText}>
          Time Left
        </text>
        <text
          x="18"
          y="24"
          textAnchor="middle"
          className={styles.timeRemaining}
        >
          {formatTime(timeRemaining)}
        </text>
      </svg>
    </div>
  );
};

export default Timer;
