import React from "react";
import Button from "../../shared/components/button";
import Nav from "../Nav";
import Card from "./Card";
import styles from "./styles.module.css";

const ExamSimulator = () => {
  return (
    <React.Fragment>
      <header className={styles.background_container}>
        <div className={styles.appWrapper} style={{ height: "100%" }}>
          <Nav />
          <div className={styles.container}>
            <p className={`${styles.title} ${styles.header}`}>Exam Simulator</p>
          </div>
        </div>
      </header>

      <div className={styles.appWrapper}>
        <div className={styles.container}>
          <div className={styles.breadcrumbContainer}>
            <div className={styles.breadcrumbHeader}>
              <button className={styles.breadcrumbText}>Home</button>
              <img src="/svg/polygon.svg" alt="Breadcrumb separator" />
              <button className={styles.breadcrumbText}>Categories</button>
              <img src="/svg/polygon.svg" alt="Breadcrumb separator" />
              <button className={styles.breadcrumbText}>
                Exam Simulator Details
              </button>
            </div>
          </div>

          <div className={styles.content}>
            <section className={styles.group}>
              <div className={styles.main}>
                <img
                  className={styles.image}
                  src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-7.png"
                  alt="demo"
                />

                <p className={styles.text}>Web Development</p>
                <Quizzes hiddenFrom="mobile" />
                <Description />
                <SuggestionsCards hiddenFrom="mobile" />
              </div>

              <aside className={styles.aside}>
                <Quizzes visibleFrom="desktop" />
                <SuggestionsCards visibleFrom="desktop" />
              </aside>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const Quizzes = ({ visibleFrom, hiddenFrom }) => {
  return (
    <div
      className={styles.suggestions}
      data-hidden-from={hiddenFrom}
      data-visible-from={visibleFrom}
    >
      <Button className={styles.examButton} text="Exam Simulator" />
      {Array(3)
        .fill(0)
        .map((_, index) => (
          <button className={styles.quiz}>
            <span>Quiz {index + 1}</span>
          </button>
        ))}
      <p className={styles.more}>...more</p>
      <button className={`${styles.killMistake} ${styles.quiz}`}>
        <span>Kill Mistake</span>
      </button>
    </div>
  );
};

const SuggestionsCards = ({ visibleFrom, hiddenFrom }) => {
  return (
    <div
      className={styles.suggestions}
      data-hidden-from={hiddenFrom}
      data-visible-from={visibleFrom}
    >
      <p className={styles.topic}>What will be your next learning topic?</p>
      {testData.map((item, index) => (
        <Card
          key={index}
          itemId={item.itemId}
          id={item.id}
          title={item.title}
          thumbnail={item.thumbnail}
          price={item.price}
          level={item.level}
          category={item.category}
          status={item.status}
          handleDelete={item.handleDelete}
          loading={item.loading}
          handlePaid={item.handlePaid}
        />
      ))}
    </div>
  );
};

const Description = () => {
  return (
    <div className={styles.details}>
      {["Description", "Instructions", "Result"].map((title, index) => (
        <div key={index}>
          <p className={styles.title} style={{ marginBottom: "30px" }}>
            {title}
          </p>
          <p className={styles.description}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. Lorem Ipsum is simply
            dummy text of the printing and typesetting industry. Lorem Ipsum has
            been the industry's standard dummy text ever since the 1500s when an
            unknown printer took a galley of type and scrambled it to make a
            type specimen book.
          </p>
        </div>
      ))}
    </div>
  );
};

const testData = [
  {
    itemId: 101,
    id: "001",
    title: "Introduction to JavaScript",
    thumbnail:
      "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-7.png",
    price: 29.99,
    level: "Beginner",
    category: "Programming",
    status: "Available",
    handleDelete: () => console.log("Delete item 001"),
    loading: false,
    handlePaid: () => console.log("Pay for item 001")
  },
  {
    itemId: 102,
    id: "002",
    title: "Advanced CSS Techniques",
    thumbnail:
      "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-7.png",
    price: 49.99,
    level: "Intermediate",
    category: "Web Design",
    status: "In Cart",
    handleDelete: () => console.log("Delete item 002"),
    loading: false,
    handlePaid: () => console.log("Pay for item 002")
  },
  {
    itemId: 103,
    id: "003",
    title: "Full-Stack Web Development",
    thumbnail:
      "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-7.png",
    price: 99.99,
    level: "Advanced",
    category: "Development",
    status: "Pending",
    handleDelete: () => console.log("Delete item 003"),
    loading: false,
    handlePaid: () => console.log("Pay for item 003")
  }
];

export default ExamSimulator;
