import { useEffect, useState } from "react";

const useSessionStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  });

  useEffect(() => {
    if (storedValue !== null) {
      sessionStorage.setItem(key, JSON.stringify(storedValue));
    } else {
      sessionStorage.removeItem(key);
    }
  }, [key, storedValue]);

  return [storedValue, setStoredValue];
};

export { useSessionStorage };
