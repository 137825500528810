import React, { createContext, useContext } from "react";
import { useSessionStorage } from "./useSessionStorage";

export const QuizContext = createContext();

export const QuizProvider = ({ children }) => {
  const [quiz, setQuiz] = useSessionStorage("quiz", []);
  const [currentQuestion, setCurrentQuestion] = useSessionStorage(
    "currentQuestion",
    { id: quiz?.questions?.[0]?.question || null, number: 0 }
  );

  const updateQuizAnswers = (selectedAnswers) => {
    const quiz = JSON.parse(sessionStorage.getItem("quiz")) || [];

    const updatedQuiz = quiz.questions.map((q) => {
      if (q.question === currentQuestion.id) {
        return {
          ...q,
          answers: selectedAnswers
        };
      }
      return q;
    });
    sessionStorage.setItem(
      "quiz",
      JSON.stringify({ ...quiz, questions: updatedQuiz })
    );
    setQuiz({ ...quiz, questions: updatedQuiz });
  };

  const handleBookmark = () => {
    const quiz = JSON.parse(sessionStorage.getItem("quiz")) || [];

    const updatedQuiz = quiz.questions.map((q) => {
      if (q.question === currentQuestion.id) {
        return {
          ...q,
          bookmarked: !q.bookmarked
        };
      }
      return q;
    });
    sessionStorage.setItem(
      "quiz",
      JSON.stringify({ ...quiz, questions: updatedQuiz })
    );
    setQuiz({ ...quiz, questions: updatedQuiz });
  };

  return (
    <QuizContext.Provider
      value={{
        quiz,
        setQuiz,
        currentQuestion,
        setCurrentQuestion,
        updateQuizAnswers,
        handleBookmark
      }}
    >
      {children}
    </QuizContext.Provider>
  );
};

export const useQuiz = () => {
  const context = useContext(QuizContext);
  if (!context) {
    throw new Error("useQuiz must be used within a QuizProvider");
  }
  return context;
};
