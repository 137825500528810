import BackupTableIcon from "@mui/icons-material/BackupTable";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import React, { useRef, useState } from "react";
import { request } from "../../../../../core/api/request";
import Button from "../../../../../shared/components/button";
import styles from "./styles.module.css";

const ImportModal = ({ setOpenModal, quizId }) => {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      setLoading(true);
      request
        .createAndUpload(`quiz/question/doc/${quizId}`, formData)
        .finally(() => {
          setLoading(false);
          setOpenModal(false);
        });
    }
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <p className={styles.title}>Import Questions</p>
        <div className={styles.group}>
          <div className={styles.box} onClick={handleIconClick}>
            <p className={styles.tooltip}>Word</p>
            {file ? (
              <CheckCircleIcon style={{ color: "green" }} />
            ) : (
              <DocumentScannerIcon />
            )}
            <input
              type="file"
              accept=".docx"
              ref={fileInputRef}
              onChange={(e) => setFile(e.target.files[0])}
              style={{ display: "none" }}
            />
          </div>
          <div className={styles.box}>
            <p className={styles.tooltip}>Excel</p>
            <BackupTableIcon className={styles.disabled} />
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            variant="outline"
            onClick={() => setOpenModal(false)}
            className={styles.button}
            text="Cancel"
          />
          <Button
            onClick={handleFileChange}
            className={styles.button}
            text="Import"
            loading={loading}
            disabled={!file}
          />
        </div>
      </div>
    </div>
  );
};

export default ImportModal;
