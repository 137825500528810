import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { request } from "../../../../core/api/request";

export const useQuestionModal = ({
  quizId,
  questionEditing,
  questions,
  setQuestionEditing
}) => {
  const [questionText, setQuestionText] = useState("");
  const [questionImage, setQuestionImage] = useState("");
  const [choices, setChoices] = useState([{ choiceText: "", image: "" }]);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (questionEditing) {
      setQuestionText(questionEditing.questionText);
      setQuestionImage(
        questionEditing.questionImage
          ? `${process.env.REACT_APP_API}${questionEditing.questionImage.filePath}`
          : ""
      );
      setChoices(
        questionEditing.choices.map((choice) => ({
          choiceText: choice.text,
          image: choice.image
            ? `${process.env.REACT_APP_API}${choice.image.filePath}`
            : ""
        }))
      );
      setCorrectAnswers(questionEditing.correctAnswers);
    }
  }, [questionEditing]);

  const addChoice = (index) => {
    const newChoices = [
      ...choices.slice(0, index + 1),
      { choiceText: "", image: "" },
      ...choices.slice(index + 1)
    ];
    setChoices(newChoices);
  };

  const handleChoiceChange = (index, field, value) => {
    const updatedChoices = [...choices];
    updatedChoices[index][field] = value;
    setChoices(updatedChoices);
  };

  const handleImageUpload = (index, file) => {
    const updatedChoices = [...choices];
    updatedChoices[index].image = new File([file], `choiceImage${index}`, {
      type: file.type,
      lastModified: file.lastModified
    });
    setChoices(updatedChoices);
  };

  const handleReset = () => {
    setQuestionText("");
    setQuestionImage("");
    setChoices([{ choiceText: "", image: "" }]);
    setCorrectAnswers([]);
    setQuestionEditing(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (correctAnswers.length === 0) {
      toast.error("Please select at least one correct answer");
      return;
    }

    if (choices.some((choice) => !choice.choiceText && !choice.image)) {
      toast.error("Please enter a choice text or upload an image");
      return;
    }

    const formData = new FormData();
    formData.append("quiz", quizId);
    formData.append("questionText", questionText);
    formData.append("answersCount", choices.length);

    if (questionImage instanceof File) {
      formData.append("files", questionImage);
    }
    choices.forEach((choice, index) => {
      formData.append(`choiceText${index}`, choice.choiceText);
      if (choice.image instanceof File) {
        formData.append("files", choice.image);
      }
    });
    correctAnswers.forEach((answer, index) => {
      formData.append(`correctAnswers[${index}]`, answer);
    });

    setLoading(true);
    try {
      if (questionEditing) {
        await request.updateAndUpload(
          `quiz/question/${questionEditing._id}`,
          formData,
          "PUT"
        );
        toast.success("Question updated successfully");
      } else {
        await request.createAndUpload("quiz/question", formData);
        toast.success("Question added successfully");
        handleReset();
      }
    } catch {
      toast.error("Failed to add question");
    } finally {
      setLoading(false);
    }
  };

  const deleteChoice = (index) => {
    const updatedChoices = [...choices];
    updatedChoices.splice(index, 1);
    setChoices(updatedChoices);
  };

  const handleCheckBox = (index) => {
    setCorrectAnswers((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const handlePreviousQuestion = () => {
    if (questionEditing) {
      const index = questions.findIndex((q) => q._id === questionEditing._id);
      if (index > 0) setQuestionEditing(questions[index - 1]);
    }

    if (!questionEditing) {
      setQuestionEditing(questions[questions.length - 1]);
    }
  };

  const handleNextQuestion = () => {
    if (questionEditing) {
      const index = questions.findIndex((q) => q._id === questionEditing._id);
      if (index < questions.length - 1)
        setQuestionEditing(questions[index + 1]);
      else handleReset();
    }
  };

  return {
    questionText,
    setQuestionText,
    questionImage,
    setQuestionImage,
    choices,
    correctAnswers,
    loading,
    addChoice,
    handleChoiceChange,
    handleImageUpload,
    handleSubmit,
    deleteChoice,
    handleCheckBox,
    handlePreviousQuestion,
    handleNextQuestion
  };
};
