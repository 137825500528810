import MenuIcon from "@mui/icons-material/Menu";
import React, { useState } from "react";
import { useQuiz } from "../../hooks/QuizContext";
import quizLogo from "../assets/quiz_logo.svg";
import QuestionDetails from "./components/QuestionDetails";
import QuestionModal from "./components/QuestionModal";
import Timer from "./components/Timer/index";
import styles from "./styles.module.css";

const Quiz = () => {
  const [openModal, setOpenModal] = useState(false);
  const { quiz } = useQuiz();

  return (
    <div>
      <div className={styles.background_container}>
        <div className="appWrapper" style={{ height: "100%" }}>
          <div className={styles.logo}>
            <img src={quizLogo} alt="logo" />
            <p>All questions copyright 2024</p>
          </div>

          <div className={styles.container}>
            <div className={styles.header}>
              <div className={styles.details}>
                <img
                  className={styles.quizLogo}
                  src={`${process.env.REACT_APP_API}${quiz?.image?.filePath}`}
                  alt="quiz"
                />
                <p>{quiz?.title}</p>
                <button onClick={() => setOpenModal((prev) => !prev)}>
                  <MenuIcon
                    style={{
                      color: openModal ? "var(--primary-color)" : "white"
                    }}
                  />
                  See All Questions
                </button>
              </div>
              <Timer duration={30 * 60} />
              <QuestionModal
                openModal={openModal}
                setOpenModal={setOpenModal}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="appWrapper">
        <div className={styles.container}>
          <QuestionDetails />
        </div>
      </div>
    </div>
  );
};

export default Quiz;
