import React from 'react';
import styles from "./styles.module.css";
const Step4 = ({ data, onInputChange }) => {
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const learningpace = checked
      ? [...data.learningpace, value]
      : data.learningpace.filter((pace) => pace !== value);

    onInputChange({ stepPersonalize_4: { ...data, learningpace } });
  };

  const handleRadioChange = (name) => (e) => onInputChange({ stepPersonalize_4: { ...data, [name]: e.target.value } });

  return (
    <div  className={styles.formcontent}>
      <p>Learning Pace</p>
    
<div className={styles.personlizestep}>
  <div className={styles.personlizesquestion}>
    <label>What pace of learning do you prefer?</label>
    <div className={styles.checkboxGroup}>
      <input
        type="checkbox"
        id="intensive"
        name="learningpace"
        className={styles.hiddenCheckbox}
        value="Intensive (e.g., Bootcamp)"
        checked={data.learningpace.includes("Intensive (e.g., Bootcamp)")} onChange={handleCheckboxChange}
      />
      <label htmlFor="intensive" className={styles.customLabel}>
        Intensive (e.g., Bootcamp)
      </label>
    </div>

    <div className={styles.checkboxGroup}>
      <input
        type="checkbox"
        id="regular"
        name="learningpace"
        className={styles.hiddenCheckbox}
        value="Regular and Gradual"
        
        checked={data.learningpace.includes("Regular and Gradual")} onChange={handleCheckboxChange}
      />
      <label htmlFor="regular" className={styles.customLabel}>
        Regular and Gradual
      </label>
    </div>

    <div className={styles.checkboxGroup}>
      <input
        type="checkbox"
        id="selfpaced"
        name="learningpace"
        className={styles.hiddenCheckbox}
        value="Self-paced (No time constraints)"
        checked={data.learningpace.includes("Self-paced (No time constraints)")} onChange={handleCheckboxChange}
      />
      <label htmlFor="selfpaced" className={styles.customLabel}>
        Self-paced (No time constraints)
      </label>
    </div>
  </div>
  <div className={styles.personlizesquestion}>
    <label>Do you have specific preferences for learning times ?</label>
    <div>
      <div className={styles.checkboxGroup}>
        <input type="radio" id="weekend" name="dayslearning"
          checked={data.dayslearning === "weekend"} onChange={handleRadioChange("dayslearning")} 
          className={styles.hiddenCheckbox} value='weekend' 
           />
        <label htmlFor="weekend" className={styles.customLabel}>
          weekend
        </label>
      </div>

    </div>
    <div>
      <div className={styles.checkboxGroup}>
        <input type="radio" id="weekdays" name="dayslearning"
          checked={data.dayslearning === "weekdays"} onChange={handleRadioChange("dayslearning")}
          className={styles.hiddenCheckbox} value='weekdays' 
           />
        <label htmlFor="weekdays" className={styles.customLabel}>
          weekdays
        </label>
      </div>

    </div>
    <div>
      <div className={styles.checkboxGroup}>
        <input type="radio" id="both" name="dayslearning" className={styles.hiddenCheckbox} 
        checked={data.dayslearning === "both"} onChange={handleRadioChange("dayslearning")}
        value='both' 
         />
        <label htmlFor="both" className={styles.customLabel}>
          both
        </label>
      </div>

    </div>

    {(data.dayslearning === 'weekend' || data.dayslearning === 'weekdays' || data.dayslearning === 'both') && (
      <div className={styles.additionalInfo}>
        <input
          type="radio"
          id="morning"
          name="timeOfDay"
          value="morning"
          className={styles.checkedRadio}
          checked={data.timeOfDay === "morning"} onChange={handleRadioChange("timeOfDay")}
        />
        <label htmlFor="morning">Morning</label>


        <input
          type="radio"
          id="afternoon"
          name="timeOfDay"
          value="afternoon"
          className={styles.checkedRadio}
          checked={data.timeOfDay === "afternoon"} onChange={handleRadioChange("timeOfDay")}
        />
        <label htmlFor="afternoon">Afternoon</label>

        <input
          type="radio"
          id="evening"
          name="timeOfDay"
          value="evening"
          className={styles.checkedRadio}
          checked={data.timeOfDay === "evening"} onChange={handleRadioChange("timeOfDay")}
        />
        <label htmlFor="evening">Evening</label>
      </div>
    )}
  </div>
  </div>
  </div>
  );
};

export default Step4;
