import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Checkbox, TextField } from "@mui/material";
import React from "react";
import Button from "../../../../../shared/components/button";
import { useQuestionModal } from "../../hooks/useQuestionModal";
import Upload from "./Upload";
import styles from "./styles.module.css";

const QuestionModal = ({
  setOpenModal,
  quizId,
  questions,
  setQuestionEditing,
  questionEditing
}) => {
  const {
    questionText,
    setQuestionText,
    questionImage,
    setQuestionImage,
    choices,
    correctAnswers,
    loading,
    addChoice,
    handleChoiceChange,
    handleImageUpload,
    handleSubmit,
    deleteChoice,
    handleCheckBox,
    handlePreviousQuestion,
    handleNextQuestion
  } = useQuestionModal({
    quizId,
    questionEditing,
    setQuestionEditing,
    questions
  });

  const handleCancel = () => {
    setQuestionEditing(null);
    setOpenModal(false);
  };
  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <div className={styles.header}>
          <CancelIcon fontSize="small" onClick={handleCancel} />

          <div className={styles.titleContainer}>
            <KeyboardArrowLeftIcon
              fontSize="small"
              onClick={handlePreviousQuestion}
            />
            <h2>{questionEditing ? "Edit" : "Add New"} Question</h2>
            <KeyboardArrowRightIcon
              fontSize="small"
              onClick={handleNextQuestion}
            />
          </div>
        </div>

        <form onSubmit={handleSubmit} className={styles.container}>
          <div className={styles.box} style={{ marginLeft: "50px" }}>
            <Upload
              fileUpload={questionImage}
              setFileUpload={setQuestionImage}
            />
            <TextField
              label="Question Text"
              variant="outlined"
              fullWidth
              required
              value={questionText}
              onChange={(e) => setQuestionText(e.target.value)}
            />
          </div>

          {choices.map((choice, index) => (
            <div key={index} className={styles.choiceContainer}>
              <Checkbox
                onChange={() => handleCheckBox(index + 1)}
                checked={correctAnswers?.includes(index + 1)}
                color="success"
              />

              <div className={styles.box} style={{ flex: 2 }}>
                <Upload
                  fileUpload={choice.image}
                  setFileUpload={(file) => handleImageUpload(index, file)}
                />
                <TextField
                  label={`Choice ${index + 1} Text`}
                  variant="outlined"
                  fullWidth
                  value={choice.choiceText}
                  onChange={(e) =>
                    handleChoiceChange(index, "choiceText", e.target.value)
                  }
                />
              </div>
              <div>
                <div className={styles.icons}>
                  <AddCircleIcon
                    fontSize="small"
                    onClick={() => addChoice(index)}
                    style={{ color: "var(--secondary-color)" }}
                  />
                  <DeleteIcon
                    onClick={() => deleteChoice(index)}
                    fontSize="small"
                    style={{ color: "#ccc" }}
                  />
                </div>
              </div>
            </div>
          ))}

          <Button
            text={`${questionEditing ? "Update" : "Add"} Question`}
            className={styles.button}
            loading={loading}
            variant="outline"
            type="submit"
          />
        </form>
      </div>
    </div>
  );
};

export default QuestionModal;
